<template>
    <BreadCrumb :breadcrumbs="breadcrumbs" />
    <Alert :class="alertClass" v-show="alertContent" @closeAlert="alertContent = ''">{{alertContent}}</Alert>
    <Modal v-show="showModal" @closeModal="showModal = false" @confirmModal="confirmModal"/>
    <Table :tableBody="tableBody" :tableHeader="tableHeader">
      <form class="form-inline">
        <div class="input-group mb-3 w-50 ms-1">
          <span class="input-group-text fa fa-search"/>
          <input type="text" class="form-control" placeholder="Search by Card Number or Order Id" v-model="term" @input="getList">
        </div>
      </form>
      <div class="mb-3 ms-2">
          <span class="me-4">Total Value: ${{total.toFixed(2)}}</span>
          <span>Total Canceled: ${{totalCanceled.toFixed(2)}}</span>
      </div>
      <div class="mb-3 row">
        <select v-model="type" class="form-select col-2 ms-3 me-5" style="width: 200px !important" @change="getList">
          <option v-for="(item, key) in retailerMap" :key="key" :value="item">{{item}}</option>
        </select>
        <select v-model="size" class="form-select col-2" style="width: 80px !important" @change="getList">
          <option v-for="(item, key) in pageSize" :key="key" :value="item">{{item}}</option>
        </select>
      </div>
    </Table>
</template>

<script>
import axios from 'axios'

export default {
  name: 'Transaction',
  mounted() {
      this.getList()
  },
  data() {
    return {
      tableHeader: ["Date", "Card Number", "Retailer", "Order Id", "Amount", "Action"],
      tableBody: [],
      breadcrumbs: [{ name: "Gift Card", link: "/giftcard" }, { name: "Transaction" }],
      showModal: false,
      currentId: "",
      alertContent: "",
      alertClass: "alert-danger",
      type: "Best Buy",
      retailerMap: {1: "Amazon", 2: "Best Buy", 3: "Target", 4: "Sams Club", 5: "Sephora", 6: "eBay", 7: "Staples"},
      pageSize: [25, 100, 500],
      size: 25,
      total: 0,
      totalCanceled: 0,
      term: ""
    }
  },
  methods: {
    openModal(id) {
      this.showModal = true;
      this.currentId = id;
    },
    confirmModal() {
      axios.delete("/giftcard/transaction/" + this.currentId).then(() => {
          this.getList();
      }).catch(error => {
          this.alertContent = error.response ? error.response.data : error;
          this.alertClass = "alert-danger";
      })
      this.showModal = false;
    },
    getList() {
      axios.get("/giftcard/transaction?type=" + this.type + "&term=" + this.term + "&sort=date,desc&size=" + this.size).then(response => {
          var openModal = this.openModal;
          this.total = 0;
          this.totalCanceled = 0;
          this.tableBody = response.data.map(item => {
            this.total += parseFloat(item.amount);
            this.totalCanceled += (parseFloat(item.amount) < 0 ? parseFloat(item.amount) : 0);
            return {
              date: (new Date(item.createdDate).toLocaleString()),
              number: item.giftcard,
              merchantType: item.merchantType,
              orderId: item.orderId,
              value: item.amount,
              action: [{ type: "link", link: "/giftcard/transaction/edit?id=" + item.id, class: "btn btn-primary btn-sm fa fa-pencil" },
                    { type: "button", click: function() { openModal(item.id) }, name: "<span class='fa fa-trash'></span>", class: "btn-danger" }]
            }
          })
      }).catch(error => {
          this.alertContent = error.response ? error.response.data : error;
          this.alertClass = "alert-danger";
      })
    }
  }
}
</script>

<style scoped>
.checkbox-group {
  position: relative;
  top: 8px;
}

.checkbox-group > .form-check-label {
  margin-right: 10px;
}

.form-check-input[type=checkbox] {
  margin-right: 2px;
}
</style>